<template>
<div>
  <div>
    <h3>Synthèse des sections</h3>
  </div>
  
  <br><br>
  <app-listesection></app-listesection>
  
  </div>
</template>

<script>
  
  import AppListesection from '../components/AppListesection'
  
  

  export default {
    name: 'Listesection',

    components: {
      
      AppListesection,
     
      
      
    },
  }
</script>