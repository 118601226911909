<template>
<div>
  <div>

  </div>
  <app-login />
  <br><br>
  

  </div>
</template>

<script>
  
  
  import AppLogin from '../components/AppLogin'


  export default {
    name: 'Login',

    components: {
      AppLogin,
      
            
    },
  }
</script>