<template>
  <div>
       <v-card class="mx-auto" max-width="300" tile>
      <v-list dense>
        <v-subheader>REPORTS</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in users" :key="item.section">
            <v-list-item-icon>
              <v-icon ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title > {{ item.section }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      users: [],
      section: [],
    };
  },

  created() {
    this.initialize();
    
  },

  updated() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios
        .post("https://testvue.futurlearning.fr/php/apicompta.php?action=readt")
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.message;
          } else {
            this.users = response.data.users;
            
          }
        });
      // this.json.data = this.users;
    },

    toFormData: function (obj) {
      let fd = new FormData();
      for (let i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },

  mounted() {},
};
</script>
