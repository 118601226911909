<template>
  <div>
    <div class="my-2">
      <v-btn
        color="success"
        dark
        v-on:click="exportTableToExcel('extraction', 'Extraction-adhérents')"
      >
        Exporter en Excel
      </v-btn>
      <h5>L'export permet de récurer l'intégralité des données du tableau. <br> Le téléchargement met 1 seconde à se lancer.</h5>
      <br>
      <p> Pour accéder aux responsables légaux et infos Judo cliquez sur les icones respectifs : <v-icon medium  class="mr-2" color="primary" > mdi-account-group  </v-icon>
        <v-icon medium  class="mr-2" color="teal accent-4" > mdi-kabaddi   </v-icon></p>
    </div> 

    <v-data-table
      id="extraction"
      :headers="headers"
      :items="users"
      :search="search"
      sort-by="calories"
      
      class="elevation-1"
      dense
      :items-per-page="20"      
    >
    
            

      <template v-slot:item.depense="{ item }">
        <div v-if="item.depense > 0" class="red--text font-weight-bold">
          {{ item.depense }}
        </div>
        <div v-else><p>-</p></div>
      </template>

      <template v-slot:item.treso="{ item }">
        <div v-if="item.treso==1" >
      <v-icon medium :color="getColor(item.treso)" >mdi-check</v-icon>  
      </div> 
      <div v-else-if="item.treso==0">
        <v-icon medium :color="getColor(item.treso)" >mdi-close</v-icon>  
      </div>   
       <div v-else>
        <v-icon medium :color="getColor(item.treso)" >mdi-piggy-bank</v-icon>  
      </div> 
    </template>

      <template v-slot:item.recette="{ item }">
        <div v-if="item.recette > 0" class="green--text font-weight-bold">
          {{ item.recette }}
        </div>
        <div v-else><span class="center"> - </span></div>
      </template>

      <template v-slot:item.datenaissance="{ item }">
        {{
          new Date(item.datenaissance).toLocaleString(fr - FR, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              filled
              rounded
              dense
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" disabled>
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.datesaisie"
                        label="Date valeur"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.section"
                        label="Section"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.categorie"
                        label="Catégorie"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.intitule"
                        label="Intitulé"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.recette"
                        label="Recette"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.depense"
                        label="dépense"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.piececompta"
                        label="N° pièce comptable"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.modepaiement"
                        label="Moyen de paiement"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.id"
                        label="ID BDD"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Valider </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogrpl"
            transition="dialog-top-transition"
            max-width="700"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" hidden>From the top</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>Synthèse repésentants légaux</v-toolbar>
                <v-card-text>
                  <div class="text-h5 pa-2 text-decoration-underline">Représentant légal 1 :</div>
                  <div class="text-h6 pa-1"> <b> Nom - prénom : </b> {{ editedItem.nrpl1 }}</div>
                  <div class="text-h6 pa-1"> <b> Email :</b> {{ editedItem.mrpl1 }}</div>
                  <div class="text-h6 pa-1"> <b>Téléphone :</b>  {{ editedItem.trpl1 }}</div>
                  <div class="text-h5 pa-2 text-decoration-underline">Représentant légal 2 :</div>
                  <div class="text-h6 pa-1"><b> Nom - prénom : </b> {{ editedItem.nrpl2 }}</div>
                  <div class="text-h6 pa-1"><b> Email :</b> {{ editedItem.mrpl2 }}</div>
                  <div class="text-h6 pa-1"><b>Téléphone :</b> {{ editedItem.trpl2 }}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

           <v-dialog
            v-model="dialogrpl2"
            transition="dialog-top-transition"
            max-width="700"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" hidden>From the top</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>Informations Judo</v-toolbar>
                <v-card-text>
                  <div class="text-h5 pa-2 text-decoration-underline">Mon enfant est autorisé à quitter le cours de judo seul :</div>
                  <div class="text-h6 pa-1"> <b> Votre réponse : </b> {{ editedItem.autorisation }}</div>
                  <div class="text-h5 pa-2 text-decoration-underline">Si, NON qui autorisez-vous ?</div>
                  <div class="text-h6 pa-1"> <b> Votre réponse :</b> {{ editedItem.qui }}</div>
                  
                  <div class="text-h5 pa-2 text-decoration-underline">Votre enfant a-t-il des allergies ? </div>
                  <div class="text-h6 pa-1"><b> Votre réponse : </b> {{ editedItem.allergie }}</div>
                  
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon medium @click="showrpl(item)" class="mr-2" color="primary" > mdi-account-group  </v-icon>
        <v-icon medium @click="showrpl2(item)" class="mr-2" color="teal accent-4" > mdi-kabaddi   </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    users: [],

    liste: ["0", "1"],
    search: "",
    errorMsg: "",
    successMsg: "",
    itemForm: "",
    dialog: false,
    dialogrpl: false,
    dialogrpl2: false,
    dialogDelete: false,
    headers: [
      {
        text: "Nom",
        align: "start",
        value: "nom",
        width: "5%"
      },
      { text: "Prénom", value: "prenom", width: "10%" },
      { text: "Email", value: "email", width: "10%"},
      { text: "Téléphone", value: "telephone", width: "10%"},
      { text: "Date de naissance", value: "datenaissance", width: "10%" },
      { text: "Adresse", value: "adresse", width: "10%" },
      { text: "Code postal", value: "codepostal", width: "10%" },
      { text: "Ville", value: "ville", width: "10%" },
      { text: "Section", value: "Section", width: "10%" },
      { text: "Formule", value: "Descformule", width: "10%"},
      { text: "Tarif", value: "Prixformule", width: "10%" },
      { text: "Paiement validé", value: "treso", width: "10%"},        
      { text: "Plus d'infos", value: "actions", sortable: false, width: "10%" },      
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Datesaisie: "",
      section: "",
      categorie: "",
      intitule: "",
      recette: "",
      depense: "",
      piececompta: "",
      modepaiement: "",
    },
    params: {
      paramsection: "",
    },

    defaultItem: {
      nom: "",
      prenom: "",
      ville: "",
      Section: "",
      Prixformule: "",
      idinscription: "",
      treso: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Faire une correction !!!";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {},

  updated() {
    
  },

  methods: {
    initialize() {
      
      axios
        .get("https://testvue.futurlearning.fr/php/apicompta.php?action=readbureau")
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.message;
          } else {
            this.users = response.data.users;
          }
        });
      // this.json.data = this.users;
    },

    priceFormat(value) {
      return "$ " + value;
    },

    exportTableToExcel(tableID, filename = "") {
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },

    getColor (calories) {
        if (calories == 0) return 'red'
        else if (calories==2) return 'orange'
        else return 'green'
      },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    showrpl(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogrpl = true;
    },
     showrpl2(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogrpl2 = true;
    },

    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    toFormData: function (obj) {
      let fd = new FormData();
      for (let i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },

    save: async function () {
      if (this.editedIndex > -1) {
        var itemForm = this.toFormData(this.editedItem);

        axios
          .post("https://testvue.futurlearning.fr/php/apicompta.php?action=updateline", itemForm)
          .then(function () {});
      }

      await this.close();
    },
  },
};
</script>

<style scoped>
.petit {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;

}

</style>