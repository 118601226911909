<template>
  <v-card elevation="2" outlined>
    <v-simple-table dense>


      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Catégories</th>
            <th class="text-left">Recettes</th>
            <th class="text-left">Dépenses</th>
            <th class="text-left">Solde</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in section" :key="item.name">
            <td>{{ item.categorie }}</td>
            <td>{{ item.recette }}</td>
            <td>{{ item.depense }}</td>
            <td>{{ item.solde }}</td>
          </tr>
        </tbody>
      </template>
      
    </v-simple-table>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    axios
      .get("https://testvue.futurlearning.fr/php/apicompta.php?action=readcategorie")
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.message;
        } else {
          this.section = response.data.users;
        }
      });
  },

  data() {
    return {
      section: [],
    };
  },
};
</script>