<template>
  <div class="mx-auto">
    <div class="row">
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        sort-by="calories"
        class="elevation-1 center"
        group-by="section"
        dense
        :items-per-page="20"
      >
        <template v-slot:item.depense="{ item }">
          <div v-if="item.depense > 0" class="red--text font-weight-bold">
            {{ item.depense }}
          </div>
          <div v-else><p>-</p></div>
        </template>

        <template v-slot:item.recette="{ item }">
          <div v-if="item.recette > 0" class="green--text font-weight-bold">
            {{ item.recette }}
          </div>
          <div v-else><span class="center"> - </span></div>
        </template>

        <template v-slot:item.solde="{ item }">
          <div v-if="item.solde > 0" class="green--text font-weight-bold">
            {{ item.solde }}
          </div>
          <div v-else>
            <div class="red--text font-weight-bold">
              {{ item.solde }}
            </div>
          </div>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <h3>Synthèse par section :</h3>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Rechercher"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    users: [],
    liste: ["0", "1"],
    search: "",
    errorMsg: "",
    successMsg: "",
    headers: [
      {
        text: "Section",
        align: "start",

        value: "section",
      },

      { text: "Catégorie", value: "categorie" },

      { text: "Recette", value: "recette" },
      { text: "Dépense", value: "depense" },
      { text: "solde", value: "solde" },
    ],

    editedIndex: -1,
  }),

  created() {
    this.initialize();
  },

  mounted() {},

  methods: {
    initialize() {
      axios
        .get("https://testvue.futurlearning.fr/php/apicompta.php?action=test")
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.message;
          } else {
            this.users = response.data.users;
          }
        });
    },
  },
};
</script>

<style scoped>
.petit {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 200px;
}

.row {
  justify-content: center;
}

.v-data-table {
  max-width: 600px;
}
</style>
