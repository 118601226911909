<template>
  <v-app>
    <v-navigation-drawer app permanent expand-on-hover>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="../_images/logoasct.png"  alt="logo asct"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> {{ this.$store.state.name }} </v-list-item-title>
            <v-list-item-subtitle>{{ this.$store.state.statut }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>

        <template>
          <div v-if="this.$store.state.statut === 'Trésorerie'">
            <v-list-group :value="displayvalue" large :active="false" color="grey" no-action>
          <template v-slot:activator>
             <v-list-item-icon>
              <v-icon large>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Synthèse</v-list-item-title>
          </template>
          
          <v-list-item class="pl-6" link to="/Dashboard">            
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title  >Tableau de bord</v-list-item-title>           
          </v-list-item>
          <v-list-item class="pl-6" link to="/Synthsesections" >            
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title >synthèse sections</v-list-item-title>           
          </v-list-item>          
        </v-list-group>
          </div>
          <div v-else > </div>
        </template>
          
        <template>
          <div v-if="this.$store.state.statut === 'Trésorerie'" >
                <v-list-group :value="displayvalue" large :active="false" color="grey" no-action>
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon large>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Saisie opérations</v-list-item-title>
                  </template>

                  <v-list-item class="pl-6" v-on:click="switchdisplay" link to="/Recette">
                  <v-list-item-icon>
                    <v-icon  color="green darken-2">mdi-book-plus-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ajouter une recette</v-list-item-title>
                </v-list-item>

                <v-list-item class="pl-6" link to="/depense">
                  <v-list-item-icon>
                    <v-icon  color="red">mdi-book-minus-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ajouter une dépense</v-list-item-title>
                </v-list-item> 
                  
                </v-list-group>
          </div>
          <div v-else></div>
          </template>

          <template>
            <div v-if="this.$store.state.statut === 'Trésorerie'" >
              <v-list-group :value="displayvalue" large :active="false" color="grey" no-action>
              <template v-slot:activator>
              <v-list-item-icon>
              <v-icon large>mdi-account-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Heures bénévolat</v-list-item-title>
          </template>
          
          <v-list-item class="pl-6" link to>            
            <v-list-item-icon>
              <v-icon>mdi-account-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title >synthèse</v-list-item-title>           
            </v-list-item>
            <v-list-item class="pl-6" link to:>            
            <v-list-item-icon>
              <v-icon>mdi-account-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Déclarer heures bénévolat</v-list-item-title>           
            </v-list-item>          
            </v-list-group>
            </div>
            <div v-else ></div>
          </template>

         <template>
          <div v-if="this.$store.state.statut === 'Trésorerie'" >

            <v-list-item link to="/GrandLivre">
          <v-list-item-icon>
            <v-icon large>mdi-bank-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Grands livres</v-list-item-title>
          </v-list-item>
        
            <v-list-item link to="/ListeAdherents">
          <v-list-item-icon>
            <v-icon large color="blue">mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Validation adhérents</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/Parametres">
          <v-list-item-icon>
            <v-icon large>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Paramètres</v-list-item-title>
        </v-list-item>
          </div>
          <div v-else ></div>
         </template>    

         <template>
          <div v-if="this.$store.state.statut === 'Trésorerie' " >                  
          </div>
           <div v-else-if="this.$store.state.statut === 'Bureau' " >                  
          </div>
          <div v-else >
             <v-list-item link to="/Section">
          <v-list-item-icon>
            <v-icon large color="blue-grey darken-1" >mdi-pencil-box-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Synthèse inscription</v-list-item-title>
          </v-list-item> 

            <v-list-item link to="/Respsectioncompta" >
          <v-list-item-icon>
            <v-icon large color="green">mdi-account-cash-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Synthèse compta</v-list-item-title>
        </v-list-item> 
          </div>
         </template>       
  
      </v-list>


    </v-navigation-drawer>

    <v-app-bar app>
<p>V 3.2</p>
      <app-logout></app-logout>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
       <router-view />
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import AppLogout from "../components/AppLogout";

export default {
  name: "Accueil",
  computed: {
    user() {
      return this.$store.state.user;
    },
    user1() {
      return this.$store.state.user1;
    },
    usertheatre() {
      return this.$store.state.usertheatre;
    },
  },
  components: {
    AppLogout,
  },

  data: () => ({
    displayvalue: false,
  }),

  methods: {
    switchdisplay() {
      this.displayvalue = false;
    },
  },
};
</script>
