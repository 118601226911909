<template>
  <div class="text-center">
    <v-btn
      rounded
      color="blue-grey lighten-2"
      dark
      elevation="2"
  small
  absolute
  right
   @click="logout"
    >
      Déconnexion
    </v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    
  },
  methods: {
    logout() {
      
            this.$emit("authenticated", false);
            this.$router.replace({ name: "Login" });
         
    },
    
  },
  data: () => ({
        
    
  }),
};
</script>