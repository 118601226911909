<template>
<div>
  <div>
    <h3>Saisie des dépenses</h3>
  </div>
  <app-infosection/>
  <testrequete></testrequete>
  <br><br>
  

  </div>
</template>

<script>
  

import AppInfosection from '../components/AppInfosection'
import testrequete from '../components/testrequete.vue';
  

  export default {
    name: 'Infosection',

    components: {
      
      AppInfosection,
      testrequete,
    },
  }
</script>