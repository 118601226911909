<template>
<div>
  <div>
    <h3>Saisie recettes</h3>
  </div>
  <app-table />
  <br><br>
  <app-form />

  </div>
</template>

<script>
  
  import AppForm from '../components/AppForm'
  

  export default {
    name: 'Recette',

    components: {
      
      AppForm,
      
      
    },
  }
</script>