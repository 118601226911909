<template>
  <div>
    <div>
      <h3>Synthèse des inscriptions section :</h3>
      <v-divider></v-divider>
    </div>
   
    <br />
    <app-bureau />
    
    
  </div>
</template>

<script>
import AppBureau from "../components/AppBureau";



export default {
  name: "Section",

  components: {
    AppBureau
   
   
  },
};
</script>
