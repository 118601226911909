<template>
  <div>
    <div>
      <h3>Synthèse comptable section : {{ this.$store.state.statut }}</h3>
      <v-divider></v-divider>
    </div>
   
    <br />
   
    <app-respsectioncompta></app-respsectioncompta>
    
  </div>
</template>

<script>

import AppRespsectioncompta from '../components/AppRespsectioncompta.vue'

export default {
  name: "Section",

  components: {    
   AppRespsectioncompta
  },
};
</script>