<template>
  <div>
    <v-card
      class="d-flex justify-space-around flex-direction: column"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
      flat
      tile
    >
      <v-card class="" max-width="250" outlined>
        <v-card-title>
          <v-list-item>
            <v-list-item-icon>
              <v-icon large color="blue">mdi-bank</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Solde compte courant:</v-list-item-title>
          </v-list-item>
        </v-card-title>

        <v-card-text>
          <div v-for=" item in solde" :key="item.id">
            <div class="center">
            <p>{{ item.solde }} Euros</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="" max-width="300" outlined>
        <v-card-title>
          <v-list-item>
            <v-list-item-icon>
              <v-icon large color="blue">mdi-piggy-bank </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Solde Livret A:</v-list-item-title>
          </v-list-item>
        </v-card-title>

        <v-card-text>
          <div class="center">
          <p>NC Euros</p>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="" max-width="300" outlined>
        <v-card-title>
          <v-list-item>
            <v-list-item-icon>
              <v-icon large color="green">mdi-account-multiple-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nombre d'inscrits:</v-list-item-title>
          </v-list-item>
        </v-card-title>

        <v-card-text>
          <div v-for=" item in adherent" :key="item.id">
            <div class="center">
            <p>{{ item.adherent }} adhérents</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="" max-width="300" outlined>
        <v-card-title>
          <v-list-item>
            <v-list-item-icon>
              <v-icon large>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>XXXXXXXXX:</v-list-item-title>
          </v-list-item>
        </v-card-title>

        <v-card-text>
          <div class="center">
          <p>YYYYYYYY</p>
          </div>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    axios
      .get("https://testvue.futurlearning.fr/php/apicompta.php?action=readsoldecc")
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.message;
        } else {
          this.solde = response.data.users;
        }
      });
      axios
      .get("https://testvue.futurlearning.fr/php/apicompta.php?action=readnbadherent")
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.message;
        } else {
          this.adherent = response.data.users;
        }
      });
  },

  data() {
    return {
      solde: [],
      adherent:[],
    };
  },
};
</script>

<style scoped>
.row {
  flex-direction: column;
}

.v-list-item {
    padding: 0px;  
}

.center{
  text-align: center;
  text-decoration: bold;
}


</style>
