<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      dense
      :items-per-page="20"
    >
      <template v-slot:item.depense="{ item }">
        <div v-if="item.depense > 0" class="red--text font-weight-bold">
          {{ item.depense }}
        </div>
        <div v-else><p>-</p></div>
      </template>

      <template v-slot:item.recette="{ item }">
        <div v-if="item.recette > 0" class="green--text font-weight-bold">
          {{ item.recette }}
        </div>
        <div v-else><span class="center"> - </span></div>
      </template>

      <template v-slot:item.datesaisie="{ item }">
        {{
          new Date(item.datesaisie).toLocaleString(fr - FR, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <h3>Grand livre compte courant :</h3>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" disabled>
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.datesaisie"
                        label="Date valeur"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.section"
                        label="Section"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.categorie"
                        label="Catégorie"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.intitule"
                        label="Intitulé"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.recette"
                        label="Recette"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.depense"
                        label="dépense"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.piececompta"
                        label="N° pièce comptable"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.modepaiement"
                        label="Moyen de paiement"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.id"
                        label="ID BDD"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Valider </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)" disabled> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    users: [],
    liste: ["0", "1"],
    search: "",
    errorMsg: "",
    successMsg: "",
    itemForm: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Date valeur",
        align: "start",

        value: "datesaisie",
      },
      { text: "Section", value: "section" },
      { text: "Catégorie", value: "categorie" },
      { text: "Intitulé", value: "intitule" },
      { text: "Recette", value: "recette" },
      { text: "Dépense", value: "depense" },
      { text: "N° pièce comptable", value: "piececompta" },
      { text: "Mode de paiment", value: "modepaiement" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Datesaisie: "",
      section: "",
      categorie: "",
      intitule: "",
      recette: "",
      depense: "",
      piececompta: "",
      modepaiement: "",
    },
    
    defaultItem: {
      nom: "",
      prenom: "",
      ville: "",
      Section: "",
      Prixformule: "",
      idinscription: "",
      treso: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Faire une correction !!!";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {},

  updated() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios
        .get("https://testvue.futurlearning.fr/php/apicompta.php?action=readgl")
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.message;
          } else {
            this.users = response.data.users;
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    toFormData: function (obj) {
      let fd = new FormData();
      for (let i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },

    save: async function () {
      if (this.editedIndex > -1) {
        var itemForm = this.toFormData(this.editedItem);

        axios
          .post("https://testvue.futurlearning.fr/php/apicompta.php?action=updateline", itemForm)
          .then(function () {});
      }

      await this.close();
    },
  },
};
</script>

<style scoped>
.petit {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 200px;
}
</style>
