<template>
  <div>
    <div>
      <h3>Synthèse des inscriptions section : {{ this.$store.state.statut }}</h3>
      <v-divider></v-divider>
    </div>
   
    <br />
    <app-responsable />
    
    
  </div>
</template>

<script>
import AppResponsable from "../components/AppResponsable";


export default {
  name: "Section",

  components: {
    AppResponsable,
   
  },
};
</script>


