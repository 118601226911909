<template>
  <div>
    <transition name="slide-fade">
      <v-alert
        class="mx-auto"
        min-width="500"
        max-width="800"
        dense
        type="info"
        v-if="successMsg"
      >
        <strong>{{ successMsg }}</strong>
      </v-alert>
    </transition>

    <transition name="slide-fade">
      <v-alert
        class="mx-auto"
        min-width="500"
        max-width="800"
        dense
        type="error"
        v-if="errorMsg"
      >
        <strong>{{ errorMsg }} </strong>
      </v-alert>
    </transition>

    <v-card class="mx-auto" min-width="500" max-width="800">
      <v-card-title>
        <h4>{{ nameform }}</h4>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container class="grey lighten-5" fluid>
            <v-row class="mb-6" no-gutters>
              <v-col>
                <div class="date">
                  <v-text-field
                    v-model="recetteItem.datesaisie"
                    :rules="dateRules"
                    @click="clearMsg"
                    label="Date de valeur"
                    placeholder="jj/mm/aaaa"
                    required
                    clearable
                  ></v-text-field>
                </div>
              </v-col>
              <v-col>
                <div class="date">
                  <v-select
                    v-model="recetteItem.section"
                    :items="sections"
                    item-text="Nomsection"
                    :rules="[(v) => !!v || 'Champ obligatoire']"
                    label="Sections"
                    required
                    @click="clearMsg"
                    clearable
                  ></v-select>
                </div>
              </v-col>
              <v-col>
                <div class="moyen">
                  <v-select
                    v-model="recetteItem.categorie"
                    :items="categories"
                    item-text="categorie"
                    :rules="[(v) => !!v || 'Champ obligatoire']"
                    label="Catégorie"
                    required
                    clearable
                    @click="clearMsg"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mb-6" no-gutters>
              <v-col>
                <div class="grand">
                  <v-text-field
                    v-model="recetteItem.intitule"
                    :rules="[(v) => !!v || 'Champ obligatoire']"
                    label="Intitulé"
                    required
                    clearable
                    @click="clearMsg"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col>
                <div class="date">
                  <v-text-field
                    v-model="recetteItem.depense"
                    
                    label="Montant en €"
                    required
                    clearable
                    @click="clearMsg"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mb-6" no-gutters>
              <v-col>
                <div class="moyen">
                  <v-text-field
                    v-model="recetteItem.piececompta"
                    label="N° pièce comptable"
                    clearable
                    @click="clearMsg"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col>
                <div class="grand">
                  <v-text-field
                    v-model="recetteItem.modepaiement"
                    label="Mode de paiement"
                    clearable
                    @click="clearMsg"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
              Enregistrer
            </v-btn>

            <v-btn color="error" class="mr-4" @click="reset"> Effacer </v-btn>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AppFormd",

  data: () => ({
    nameform: "Formulaire saisie des dépenses",
    valid: true,
    select: null,
    successMsg: "",
    errorMsg: "",
    sections: [],
    categories: [],
    recetteItem: {
      datesaisie: "",
      section: "",
      categorie: "",
      intitule: "",
      recette: "0",
      depense: "",
      piececompta: "",
      modepaiement: "",
    },

    montantRules: [
      (v) => !!v || "Ce champ est obligatoire !",
      (v) => /^[0-9]+$/.test(v) || "Saisir un nombre !",
    ],

    dateRules: [
      (v) => !!v || "Ce champ est obligatoire !",
      (v) =>
        /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/.test(v) ||
        "La date doit avoir un format valide jj/mm/aaaa",
    ],
  }),

  mounted() {
    axios.get("https://testvue.futurlearning.fr/php/apicompta.php?action=read").then((response) => {
      if (response.data.error) {
        this.errorMsg = response.data.message;
      } else {
        this.sections = response.data.users;
      }
    });
    axios
      .get("https://testvue.futurlearning.fr/php/apicompta.php?action=readcat")
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.message;
        } else {
          this.categories = response.data.users;
        }
      });
  },

  methods: {
    toFormData: function (obj) {
      let fd = new FormData();
      for (let i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },

    validate() {
      if (this.$refs.form.validate()) {
        var itemForm = this.toFormData(this.recetteItem);
        axios
          .post("https://testvue.futurlearning.fr/php/apicompta.php?action=create", itemForm)
          .then((response) => {
            if (response.data.error) {
              console.log(response.data.message);
              this.errorMsg = response.data.message;
               this.autoClearMsg();
            } else {
              this.successMsg = response.data.message;
               this.autoClearMsg();
            }
          });
        this.reset();
      }
    },
    clearMsg() {
      this.errorMsg = "";
      this.successMsg = "";
    },
    autoClearMsg() {
      setTimeout(this.clearMsg, 2000);
    },

    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.col {
  flex-grow: 0;
}

.card {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 250px;
}

.date {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 170px;
}
.petit {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 100px;
}

.moyen {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 300px;
}
.grand {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  width: 400px;
}

.v-card__title {
  background: lightpink;
}

/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
