<template>
  <div>
    <div>
      <h3>Tableau de bord</h3>
    </div>

    <app-card></app-card>

    <br /><br />

    <v-row>
      <v-col>
        <p>Synthèse par section</p>
        <app-sectiontab></app-sectiontab>
      </v-col>
      <v-col>
        <p>Synthèse par catégories</p>
        <app-categorietab></app-categorietab>
      </v-col>
    </v-row>
   
  </div>
</template>

<script>

import AppSectiontab from "../components/AppSectiontab";
import AppCard from "../components/AppCard";
import AppCategorietab from "../components/AppCategorietab";


export default {
  name: "dashboard",

  components: {
    AppCard,
    AppSectiontab,
    AppCategorietab,
 
    
  },
};
</script>

<style scoped>

.v-application p {

   
    font-weight: bold;
}

</style>
