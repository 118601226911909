<template>
  <div>
    <div>
      <h3>Grands livres ASCT</h3>
    </div>
    <br /><br />
    <app-table />
    <div v-if="showloader">
    
    </div>
  </div>
</template>

<script>
import AppTable from "../components/AppTable";

export default {
  name: "GrandLivre",

  data() {
    return {
    
    };
  },

  components: {
    AppTable,
  
  },
};
</script>
