<template>
  <v-card elevation="2" outlined class="mx-auto " width="600px" >
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Catégories comptables</th>
            <th class="text-left">Recettes</th>
            <th class="text-left">Dépenses</th>
            <th class="text-left">Solde</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.name">
            <td>{{ item.categorie }}</td>
            <td>{{ item.recette }}</td>
            <td>{{ item.depense }}</td>
            <td>{{ item.solde }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>

import axios from "axios";
export default {
  data() {
    return {
        users: [],
      section: [],
      params: {
        paramsection: "",
      },
    };
  },

  created() {
    this.initialize();
  },

  updated() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.params.paramsection = this.$store.state.statut;
      var paramsec = this.toFormData(this.params);
      axios
        .post("https://testvue.futurlearning.fr/php/apicompta.php?action=readrespsectioncompta", paramsec)
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.message;
          } else {
            this.users = response.data.users;
          }
        });
      // this.json.data = this.users;
    },

    toFormData: function (obj) {
      let fd = new FormData();
      for (let i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },

  mounted() {},
};
</script>
