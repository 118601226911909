<template>
  <div>
    <div></div>

    <app-adherent></app-adherent>
  </div>
</template>

<script>
import AppAdherent from "../components/AppAdherent";

export default {
  name: "ListeAdherents",

  components: {
    AppAdherent,
  },
};
</script>
