<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      dense
      :items-per-page="20"
    >
    <template v-slot:item.treso="{ item }">
        <div v-if="item.treso==1" >
      <v-icon medium :color="getColor(item.treso)" >mdi-check</v-icon>  
      </div> 
      <div v-else-if="item.treso==0">
        <v-icon medium :color="getColor(item.treso)" >mdi-close</v-icon>  
      </div>   
       <div v-else>
        <v-icon medium :color="getColor(item.treso)" >mdi-piggy-bank</v-icon>  
      </div> 
    </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <h3>Listing comptabilité des adhérents :</h3>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" disabled>
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.nom" label="Nom"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.prenom"
                        label="Prénom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.ville"
                        label="Ville"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.Section"
                        label="Section"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.Prixformule"
                        label="Tarif"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="liste"
                        :placeholder="editedItem.treso"
                        v-model="editedItem.treso"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Valider </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)" disabled> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    users: [],
    liste: ["0", "1", "2"],
    search: "",
    errorMsg: "",
    successMsg: "",
    itemForm: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Nom",
        align: "start",

        value: "nom",
      },
      { text: "Prénom", value: "prenom" },
      { text: "Ville", value: "ville" },
      { text: "Section", value: "Section" },
      { text: "Tarif", value: "Prixformule" },
      { text: "Tréso", value: "treso" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      nom: "",
      prenom: "",
      ville: "",
      Section: "",
      Prixformule: "",
      idinscription: "",
      treso: "",
    },
    defaultItem: {
      nom: "",
      prenom: "",
      ville: "",
      Section: "",
      Prixformule: "",
      idinscription: "",
      treso: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Changer le statut tréso";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {},

  methods: {
    initialize() {
      axios.get("https://testvue.futurlearning.fr/php/process.php?action=read").then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.message;
        } else {
          this.users = response.data.users;
        }
      });
    },

    getColor(calories) {
      if (calories == 0) return "red";
      else if (calories == 2) return "orange";
      else return "green";
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    toFormData: function (obj) {
      let fd = new FormData();
      for (let i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },

    save: function () {
      if (this.editedIndex > -1) {
        var itemForm = this.toFormData(this.editedItem);
        axios
          .post("https://testvue.futurlearning.fr/php/process.php?action=update", itemForm)
          .then(function (response) {
            if (response.data.error) {
              this.errorMsg = response.data.message;
            } else {
              this.successMsg = response.data.message;
            }
          });
      }
      this.initialize();
      this.close();
    },
  },
};
</script>
