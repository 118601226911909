import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import GrandLivre from '../views/GrandLivre.vue'
import Recette from '../views/Recette.vue'
import Login from '../views/Login.vue'
import ListeAdherent from '../views/ListeAdherents.vue'
import Accueil from '../views/Accueil.vue'
import Dashboard from '../views/Dashboard.vue'
import Depense from '../views/Depense.vue'
import Parametres from '../views/Parametres.vue'
import Synthsesections from '../views/synthesesections.vue'
import Section from '../views/Section.vue'
import Respsectioncompta from '../views/Respsectioncompta.vue'
import Bureau from '../views/Bureau.vue'



Vue.use(VueRouter)



const routes = [{
        path: "/",
        redirect: {
            name: "Login"
        }
    },
    {
        path: "/Login",
        name: "Login",
        component: Login
    },

    {
        path: "/Accueil",
        name: "Accueil",
        component: Accueil,
        children: [{
                path: "/Dashboard",
                name: "Dashboard",
                component: Dashboard
            },
            {
                path: "/Recette",
                component: Recette
            },
            {
                path: "/Depense",
                component: Depense
            },
            {
                path: "/Recette",
                component: Recette
            },
            {
                path: "/GrandLivre",
                component: GrandLivre
            },
            {
                path: "/ListeAdherents",
                component: ListeAdherent
            },
            {
                path: "/Recette",
                component: Recette
            },
            {
                path: "/Parametres",
                component: Parametres
            },
            {
                path: "/Section",
                name:"Section",
                component: Section
            },
            {
                path: "/Bureau",
                name:"Bureau",
                component: Bureau
            },
            {
                path: "/Respsectioncompta",
                name:"Respsectioncompta",
                component: Respsectioncompta
            },
            {
                path: "/Synthsesections",
                component: Synthsesections
            },
            //   {
            //       path: '*',
            //       redirect: {
            //           name: "Login"
            //       }
            //   },
        ],

    },
    //composants

    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router