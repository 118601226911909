<template>
  <v-app id="App">
    <!-- Sizes your content based upon application components -->

    <!-- Provides the application the proper gutter -->

    <!-- If using vue-router -->
    <router-view @authenticated="setAuthenticated" />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    authenticated: false,
    mockAccount: {
      username: "sabrina0451@gmail.com",
      password: "sabrina0451",
    },
    //
  }),

  mounted() {
    if (!this.authenticated) {
      this.$router.replace({ name: "Login" });
    }
  },
  
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    },
  },
};
</script>
