<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
        background-color="deep-purple accent-4"
        icons-and-text
        dark
        grow
      >
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab v-for="i in tabs" :key="i">
          <v-icon large>{{ i.icon }}</v-icon>
          <div class="caption py-1">{{ i.name }}</div>
        </v-tab>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginEmail"
                      :rules="loginEmailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Mot de passe"
                      hint="At least 8 characters"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" xsm="12">
                    <p v-if="error">Email ou mot de passe invalide !</p>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="success"
                      @click="validate"
                    >
                      Connexion
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="registerForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="firstName"
                      :rules="[rules.required]"
                      label="Prénom"
                      maxlength="20"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="lastName"
                      :rules="[rules.required]"
                      label="Nom"
                      maxlength="20"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Mot de passe"
                      hint="Minimum 8 charactères"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      block
                      v-model="verify"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, passwordMatch]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Confirmer votre Mot de passe"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="success"
                      @click="validate"
                      >Register</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Les mots de passes sont différents";
    },
  },
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        if (this.loginEmail != "" && this.loginPassword != "") {
          if (
            this.loginEmail == this.$store.state.user.email &&
            this.loginPassword == this.$store.state.user.password
          ) {
            this.$emit("authenticated", true);
            this.$store.state.statut = "Trésorerie";
            this.$store.state.name = "Sabrina";
            this.$router.replace({ name: "Dashboard" });
          }
          if (
            this.loginEmail == this.$store.state.user1.email &&
            this.loginPassword == this.$store.state.user1.password
          ) {
            this.$emit("authenticated", true);
            this.$store.state.statut = "Trésorerie";
            this.$store.state.name = "Héléna";
            this.$router.replace({ name: "Dashboard" });
          }
          if (
            this.loginEmail == this.$store.state.usertheatre.email &&
            this.loginPassword == this.$store.state.usertheatre.password
          ) {
            this.$store.state.statut = "Théâtre";
            this.$store.state.name = "Dominique";
            this.$store.state.nomsection = "Théâtre";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userjudo.email &&
            this.loginPassword == this.$store.state.userjudo.password
          ) {
            this.$store.state.statut = "Judo";
            this.$store.state.name = "Cédric Daniel";
            this.$store.state.nomsection = "Judo";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.useroenologie.email &&
            this.loginPassword == this.$store.state.useroenologie.password
          ) {
            this.$store.state.statut = "Œnologie";
            this.$store.state.name = "Jean-marc";
            this.$store.state.nomsection = "Oenologie";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userboxe.email &&
            this.loginPassword == this.$store.state.userboxe.password
          ) {
            this.$store.state.statut = "Boxe";
            this.$store.state.name = "Olivier";
            this.$store.state.nomsection = "Boxe";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userzumba.email &&
            this.loginPassword == this.$store.state.userzumba.password
          ) {
            this.$store.state.statut = "Zumba";
            this.$store.state.name = "Gabriela - Estelle";
            this.$store.state.nomsection = "Zumba";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.useryoga.email &&
            this.loginPassword == this.$store.state.useryoga.password
          ) {
            this.$store.state.statut = "Yoga";
            this.$store.state.name = "Nicolas";
            this.$store.state.nomsection = "Yoga";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userescalade.email &&
            this.loginPassword == this.$store.state.userescalade.password
          ) {
            this.$store.state.statut = "Escalade";
            this.$store.state.name = "Christine";
            this.$store.state.nomsection = "Escalade";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.usertennis.email &&
            this.loginPassword == this.$store.state.usertennis.password
          ) {
            this.$store.state.statut = "Tennis";
            this.$store.state.name = "Julien";
            this.$store.state.nomsection = "Tennis";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.usersophrologie.email &&
            this.loginPassword == this.$store.state.usersophrologie.password
          ) {
            this.$store.state.statut = "Sophrologie";
            this.$store.state.name = "Christian";
            this.$store.state.nomsection = "Sophrologie";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userpeinture.email &&
            this.loginPassword == this.$store.state.userpeinture.password
          ) {
            this.$store.state.statut = "Peinture";
            this.$store.state.name = "Evelyne";
            this.$store.state.nomsection = "Peinture";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userbadminton.email &&
            this.loginPassword == this.$store.state.userbadminton.password
          ) {
            this.$store.state.statut = "Badminton";
            this.$store.state.name = "Daniel";
            this.$store.state.nomsection = "Badminton";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userdanse.email &&
            this.loginPassword == this.$store.state.userdanse.password
          ) {
            this.$store.state.statut = "Dansattitude";
            this.$store.state.name = "Sébastien";
            this.$store.state.nomsection = "Dansattitude";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.usergym.email &&
            this.loginPassword == this.$store.state.usergym.password
          ) {
            this.$store.state.statut = "Gym";
            this.$store.state.name = "David";
            this.$store.state.nomsection = "Gym - Pilate";
            this.$router.replace({ name: "Section" });
          }
          if (
            this.loginEmail == this.$store.state.userbureau.email &&
            this.loginPassword == this.$store.state.userbureau.password
          ) {
            this.$store.state.statut = "Bureau";
            this.$store.state.name = "Bureau";
            this.$store.state.nomsection = "Bureau";
            this.$router.replace({ name: "Bureau" });
          } else {
            this.error = true;
          }
        } else {
          console.log("A username and password must be present");
        }

        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  data: () => ({
    dialog: true,
    error: false,
    tab: 0,
    tabs: [
      { name: "Connexion", icon: "mdi-account" },
      { name: "Enregistrez vous!", icon: "mdi-account-outline" },
    ],
    valid: true,

    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
    ],
    emailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
    ],

    show1: false,
    rules: {
      required: (value) => !!value || "Champ obligatoie.",
      min: (v) => (v && v.length >= 8) || "Minimum 8 charactères",
    },
  }),
};
</script>

<style scope>
.col-sm-3 {
  flex: 0 0 25%;
  max-width: 50%;
}
</style>
