<template>
<div>
  <div>
    <h3>Saisie des dépenses</h3>
  </div>
  
  <br><br>
  <app-formd />

  </div>
</template>

<script>
  

import AppFormd from '../components/AppFormd'
  

  export default {
    name: 'Depense',

    components: {
      
      AppFormd,
                 
    },
  }
</script>