import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

        user: {
            email: "sabrina0451@gmail.com",
            password: "sabrina0451",
            authstatut: "true"
        },
        user1: {
            email: "twamwalou@hotmail.fr",
            password: "twamwalou",
            authstatut: "true"
        },
        usertheatre: {
            email: "asct.theatre@gmail.com",
            password: "Theatreasct1!",
            authstatut: "true"
        },
        userjudo: {
            email: "judo.asct@gmail.com",
            password: "Judoasct1!",
            authstatut: "true"
        },
        useroenologie: {
            email: "jmpalmas@orange.fr",
            password: "oenologieasct1!",
            authstatut: "true"
        },
        userboxe: {
            email: "boxe.asct@gmail.com",
            password: "boxeasct1!",
            authstatut: "true"
        },
        userzumba: {
            email: "asct.zumba@gmail.com",
            password: "Zumbaasct1!",
            authstatut: "true"
        },
        useryoga: {
            email: "nicolas.coutelin@gmail.com",
            password: "Yogaasct1!",
            authstatut: "true"
        },
        userescalade: {
            email: "christine.dessaints@toussus.fr",
            password: "Escaladeasct1!",
            authstatut: "true"
        },
        usertennis: {
            email: "asct.tennis@gmail.com",
            password: "Tennisasct1!",
            authstatut: "true"
        },
        usersophrologie: {
            email: "asct.sophrologie@asctoussus.fr",
            password: "Sophrologieasct1!",
            authstatut: "true"
        },
        userpeinture: {
            email: "epoibeaula@free.fr",
            password: "Peintureasct1!",
            authstatut: "true"
        },
        userbadminton: {
            email: "daniel.moneyron@gmail.com",
            password: "Badmintonasct1!",
            authstatut: "true"
        },
        userbureau: {
            email: "contact.asct@gmail.com",
            password: "Bureauasct1!",
            authstatut: "true"
        },
        userdanse: {
            email: "asct.danse@gmail.com",
            password: "danseasct1!",
            authstatut: "true"
        },
        usergym: {
            email: "gosselindavid91@gmail.com",
            password: "gymasct1!",
            authstatut: "true"
        },
        
        statut: "",

        name: "",
        nomsection:""


    },

    getters: {

        section: state => {
            return state.section;
        }
    },

    mutations: {

        SET_Item(state, section) {
            state.section = section
        }
    },
    actions: {

        loadItems({ commit }) {
            axios
                .get("http://localhost:8080/php/apicompta.php?action=readsection")
                .then((response) => {
                    if (response.data.error) {
                        this.errorMsg = response.data.message;
                    } else {
                        commit('SET_Items', response.data.users)
                    }
                });

        }


    },
    modules: {


    }
})